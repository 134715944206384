import React, { useEffect } from "react";
import {
  FloatingHelper,
  ToggleSwitch,
  FormField,
  EmptyState,
  CustomModalLayout,
  TextButton,
  Heading,
  Modal,
  MessageModalLayout,
  Text,
  Card,
  Box,
  Page,
  Button,
  Cell,
  Layout,
  Input,
  WixDesignSystemProvider,
  Image,
  PageSection,
  Badge,
  MarketingLayout,
  AnnouncementModalLayout,
  Thumbnail,
  MarketingPageLayoutContent,
  MarketingPageLayout,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import * as Icons from "@wix/wix-ui-icons-common";
import "./App.css";
import { ReactTagManager } from "react-gtm-ts";
import * as basePlatform from "@certifiedcode/base";
import * as BC from "@certifiedcode/base-components";
import Visa from "./IconsSource/visa.svg";
import ApplePay from "./IconsSource/applepay.svg";
import GooglePay from "./IconsSource/googlepay.svg";
import MasterCard from "./IconsSource/mastercard.svg";
import Amex from "./IconsSource/amex.svg";
import Discover from "./IconsSource/discover.svg";
import JCB from "./IconsSource/jcb.svg";
import DinersClub from "./IconsSource/diners_club.svg";
import UnionPay from "./IconsSource/union_pay.svg";
import Maestro from "./IconsSource/maestro.svg";
import { useTranslation } from "react-i18next"
const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  const { t } = useTranslation()
  ReactTagManager.init(tagManagerArgs);

  const instance = new URLSearchParams(window.location.search).get("instance");

  const [isHighlighted, setIsHighlighted] = React.useState(false);
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [viewDemo, setViewDemo] = React.useState(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isOpening, setIsOpening] = React.useState(false);
  const [shownSettingsModal, setShownSettingsModal] = React.useState(false);
  const [secretKey, setSecretKey] = React.useState("");
  const [showVideo, setShowVideo] = React.useState(false);
  const [publicKey, setPublicKey] = React.useState("");
  const [isInteractiveDemoOpen, setIsInteractiveDemoOpen] =
    React.useState(false);
  const [isBusinessAppModalOpened, setIsBusinessAppModalOpened] =
    React.useState(false);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  });

  useEffect(() => {
    basePlatform.initialize({
      baseURL: "https://certifiedcode.editorx.io/stripe-pay-button/_functions", // Replace with your API base URL
      applicationID: "6717f221-8f11-4bc4-9b60-bcd7e95c0268", // Replace with your application ID
    });
    basePlatform.setIdentity(instance);
    basePlatform.setInstance(setInstanceData, setIsUpgraded);
    getSettings();
  }, []);

  // if (!instance) {
  //   return null
  // }

  const BASE_URL = `https://certifiedcode.editorx.io/stripe-pay-button/_functions`;

  function setSettings() {
    // if (isUpgraded) {
    if (!open) {
      if (secretKey === "") {
        setShownSettingsModal(true);
        return;
      }
    }
    const account =
      !open === true
        ? {
            secretKey,
            publicKey,
          }
        : {};
    setIsOpening(true);
    fetch(BASE_URL + "/settings", {
      method: "POST",
      headers: {
        Authorization: instance || "",
      },
      body: JSON.stringify({
        isEnabled: !open,
        ...account,
      }),
    })
      .then(() => {
        setShownSettingsModal(false);
        setIsOpening(false);
        setOpen(!open);
        setSecretKey("");
        setPublicKey("");
      })
      .catch(() => {});
    // } else {
    //   setIsUpgradeModalOpen(true)
    // }
  }

  function getSettings() {
    setIsOpening(true);
    fetch(BASE_URL + "/settings", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setIsOpening(false);
        setOpen(data.isEnabled);
      })
      .catch(() => {});
  }

  const isPremiumApp = true;

  const YoutubeEmbed = ({ embedId }: any) => (
    <iframe
      width="560px"
      height="315px"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title={t('youtube-title')}
    />
  );

  const renderThumbnail = ({ title, subtitle, id }: any) => (
    <Thumbnail
      onClick={() => {
        window.Paddle.Checkout.open({
          product: id,
          email: (instanceData as any)?.site?.ownerInfo?.email,
          allowQuantity: false,
        });
      }}
    >
      <Box padding="18px">
        <Box gap="12px" verticalAlign="middle">
          <Icons.Premium />
          <Box direction="vertical">
            <Text size="medium" weight="bold">
              {title}
            </Text>
            <Box>
              <Text size="small" secondary>
                {subtitle}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Thumbnail>
  );

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height="100vh">
        <Page.Header
          title={
            <Box gap={"SP2"} alignItems="middle">
              <Heading>{t('stripe-heading')}</Heading>
            </Box>
          }
          actionsBar={
            <Box gap={"SP2"} direction="horizontal">
              {isUpgraded && (
                <Button
                  prefixIcon={<Icons.Reviews />}
                  skin="inverted"
                  as="a"
                  href="https://bridget.reviews.certifiedcode.us/6717f221-8f11-4bc4-9b60-bcd7e95c0268"
                  target="_blank"
                >
                  {t('review-heading')}
                </Button>
              )}
              <Button
                skin="premium"
                as="a"
                href={basePlatform.getUpgradeUrl()}
                target={"_blank"}
                prefixIcon={<Icons.PremiumFilled />}
              >
                {isUpgraded ? t('manage-plan') : t('accept-payment')} 
              </Button>
            </Box>
          }
        />
        <Page.Content>
          <Modal
            isOpen={isBusinessAppModalOpened}
            onRequestClose={() => setIsBusinessAppModalOpened(false)}
            shouldCloseOnOverlayClick
            screen="desktop"
          >
            <CustomModalLayout
              onCloseButtonClick={() => {
                setIsBusinessAppModalOpened(false);
              }}
              title={t('advanced-title')}
              content={
                <Card>
                  <MarketingPageLayoutContent
                    title={t('stripe-title')}
                    content={
                      <Text>
                        <ul>
                          <li>
                            {t('stripe-li-1')}
                          </li>
                          <li>{t('stripe-li-2')}</li>     
                          <li>{t('stripe-li-3')}</li>  
                          <Box
                            alignContent="center"
                            gap="SP1"
                            direction="horizontal"
                          >
                            <li>{t('stripe-li-4')}</li>                         
                            <Badge size="tiny" skin="warningLight">
                            {t('new-badge')}                                                      
                            </Badge>
                          </Box>
                        </ul>
                      </Text>
                    }
                    actions={
                      <Layout>
                        <Cell>
                          <Layout cols={1} gap="12px">
                            <Text size="small" weight="bold">
                            {t('plan-heading')}                             
                            </Text>
                            {renderThumbnail({
                              title: t('lifetime-title'),
                              subtitle:
                              t('lifetime-subtitle'),   
                              id: 858919,
                            })}
                            {renderThumbnail({
                              title: t('billing-title'),                              
                              subtitle: t('billing-subtitle'),                                          
                              id: 858918,
                            })}
                          </Layout>
                        </Cell>
                        <Cell>
                          <Text size="tiny">
                          {t('sale-tax-msg')}{" "}                                      
                            <a
                              href="https://www.certifiedcode.us/legal/terms-of-services"
                              target="_blank"
                            >
                              {t('sale-tax-link')}  
                            </a>
                          </Text>
                        </Cell>
                        <Cell></Cell>
                      </Layout>
                    }
                  />
                </Card>
              }
            />
          </Modal>
          <Modal isOpen={showVideo}>
            <AnnouncementModalLayout
              title={t('started-title')}                                                        
              onCloseButtonClick={() => {
                setShowVideo(false);
              }}
            >
              <YoutubeEmbed embedId="6lqD56HYkok" />
            </AnnouncementModalLayout>
          </Modal>
          <Modal isOpen={viewDemo}>
            <AnnouncementModalLayout
              title={t('wix-title')}                                                
              onCloseButtonClick={() => {
                setViewDemo(false);
              }}
            >
              <YoutubeEmbed embedId="yjMaOmmVSMo" />
            </AnnouncementModalLayout>
          </Modal>
          <Modal
            isOpen={shownSettingsModal}
            onRequestClose={() => setShownSettingsModal(false)}
            shouldCloseOnOverlayClick
            screen="desktop"
          >
            <CustomModalLayout
              primaryButtonText={t('primary-button')}                                                   
              secondaryButtonText={t('secondary-button')}                                               
              onCloseButtonClick={() => setShownSettingsModal(false)}
              secondaryButtonOnClick={() => setShownSettingsModal(false)}
              primaryButtonOnClick={() => setSettings()}
              removeContentPadding
              onHelpButtonClick={() => {
                window.open(
                  "https://stripe.com/docs/keys#reveal-an-api-secret-key-live-mode",
                  "_blank"
                );
              }}
              title={t('connect-title')}                                          
              content={
                <Page minWidth={0}>
                  <Page.Content>
                    <Box marginTop={5} display="block">
                      <Layout>
                        <Cell span={12}>
                          <Box direction="vertical" gap="SP2">
                            <FormField
                              label={t('secret-label')}                                           
                              suffix={
                                <TextButton
                                  as="a"
                                  href="https://stripe.com/docs/keys#reveal-an-api-secret-key-live-mode"
                                  target="_blank"
                                  size="small"
                                  suffixIcon={<Icons.ExternalLinkSmall />}
                                >
                                  {t('steps-button')}                                          
                                </TextButton>
                              }
                            >
                              <Input
                                required
                                clearButton
                                value={secretKey}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSecretKey(e.target.value)}
                              />
                            </FormField>
                            <FormField
                              label={t('public-label')}
                              suffix={
                                <TextButton
                                  as="a"
                                  href="https://support.certifiedcode.us/en/articles/8719738-stripe-pay-button-about-inline-checkout"
                                  target="_blank"
                                  size="small"
                                  suffixIcon={<Icons.ExternalLinkSmall />}
                                >
                                  {t('learn-button')}          
                                </TextButton>
                              }
                            >
                              <Input
                                clearButton
                                value={publicKey}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPublicKey(e.target.value)}
                              />
                            </FormField>
                          </Box>
                        </Cell>
                      </Layout>
                    </Box>
                  </Page.Content>
                </Page>
              }
            />
          </Modal>
          <Modal
            isOpen={isUpgradeModalOpen}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => setIsUpgradeModalOpen(false)}
          >
            <AnnouncementModalLayout
              theme="premium"
              title={t('accept-title')}                   
              primaryButtonText={t('upgrade-button')}  
              // linkText="Learn more"
              primaryButtonOnClick={() => {
                window.open(
                  `https://www.wix.com/apps/upgrade/${"6717f221-8f11-4bc4-9b60-bcd7e95c0268"}?appInstanceId=${
                    (instanceData as any)["instance"]["instanceId"]
                  } `
                );
              }}
              // linkOnClick={() => { window.open('https://support.certifiedcode.us/en/articles/7953534-getting-started-Stripe-pay-button#h_b92f8de467') }}
              onCloseButtonClick={() => setIsUpgradeModalOpen(false)}
            >
              <Text>
              {t('accept-text')}                                         
              </Text>
            </AnnouncementModalLayout>
          </Modal>
          <Modal isOpen={isModalOpen} screen="desktop">
            <MessageModalLayout
              primaryButtonText={t('refresh-button')}                               
              primaryButtonOnClick={() => window.location.reload()}
              title={t('refresh-title')}                              
              content={
                <Text>{t('refresh-text')}</Text> 
              }
            />
          </Modal>
          <Modal
            isOpen={isInteractiveDemoOpen}
            screen="desktop"
            shouldDisplayCloseButton={true}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => setIsInteractiveDemoOpen(false)}
          >
            <CustomModalLayout
              removeContentPadding={true}
              content={
                <iframe
                  src="https://demo.arcade.software/0PxYlTZUNVLy36nOyEma?embed&show_copy_link=true"
                  title={t('stripe-heading')}                        
                  frameBorder="0"
                  loading="lazy"
                  allowFullScreen
                  allow="clipboard-write"
                  // style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;color-scheme: light;"
                  style={{
                    width: 800,
                    height: 500,
                  }}
                ></iframe>
              }
            />
          </Modal>
          <Layout>
            {!isUpgraded && (
              <Cell span={12}>
                <Card>
                  <MarketingLayout
                    size="tiny"
                    actions={
                      <Box gap={1} verticalAlign="middle">
                        <Button
                          dataHook="dealer-placement-main-cta"
                          as="a"
                          skin="premium"
                          target="_blank"
                          href={basePlatform.getUpgradeUrl()}
                        >
                         {t('upgrade-button')}                            
                        </Button>
                        <Button
                          dataHook="dealer-placement-secondary-cta"
                          skin="premium"
                          priority="secondary"
                          onClick={() => {
                            window.Intercom(
                              t('new-msg'),                              
                              t('new-info-msg')   
                            );
                          }}
                        >
                         {t('talk-button')}                                            
                        </Button>
                      </Box>
                    }
                    title= {t('upgrade-title')}                            
                    description={t('upgrade-description')}
                  ></MarketingLayout>
                </Card>
              </Cell>
            )}
            {isUpgraded ? (
              <Cell span={12}>
                <Card>
                  <MarketingLayout
                    size="tiny"
                    title={t('looking-title')}                        
                    description={t('looking-description')}                   
                    actions={
                      <Box gap="SP1">
                        <Button
                          size="small"
                          suffixIcon={<Icons.PlaySmall />}
                          onClick={() => {
                            setViewDemo(true);
                          }}
                        >
                         {t('watch-button')}                                          
                        </Button>
                        <Button
                          size="small"
                          suffixIcon={<Icons.ExternalLinkSmall />}
                          skin="inverted"
                          onClick={() => {
                            setIsBusinessAppModalOpened(true);
                          }}
                        >
                          {t('see-button')}                                       
                        </Button>
                      </Box>
                    }
                  />
                </Card>
              </Cell>
            ) : (
              <></>
            )}
            <Cell span={12}>
              <Card>
                <Card.Header
                  title={
                    <Box gap={"SP2"}>
                      <Heading size="medium" as="h2">
                      {t('online-heading')}                             
                      </Heading>
                      {isUpgraded ? (
                        <Badge size="tiny" skin={"success"}>
                         {t('ready-badge')}                                      
                        </Badge>
                      ) : (
                        <FloatingHelper
                          target={
                            <Badge size="tiny" skin={"danger"}>
                              {t('limited-badge')}                                   
                            </Badge>
                          }
                          content={
                            <FloatingHelper.Content
                              title={t('popup-title')}       
                              body={t('popup-body')}  
                            />
                          }
                          placement="right"
                        />
                      )}
                    </Box>
                  }
                  suffix={
                    <ToggleSwitch
                      onChange={() => {
                        setSettings();
                      }}
                      size="medium"
                      checked={open}
                      disabled={isOpening}
                    />
                  }
                />
                {/* <Card.Subheader title={<Text size='small'>Pay Button can be placed everywhere on your site via Editor, and supports custom amount or pre-defined amount payment. <b>Note: Pay Button does not integrate with Wix eCommerce.</b></Text>} suffix={
                  <Button skin='light' size="small" onClick={() => { setIsHighlighted(true) }}>Explore advanced integration</Button>
                } /> */}
                <Card.Subheader
                  title={
                    <Box direction="vertical" gap="SP2">
                      <Box gap={"SP1"} verticalAlign="middle">
                        <Text size="small">{t('payment-methods')}</Text>  
                        <Image borderRadius={0} src={ApplePay} width="36px" />
                        <Image borderRadius={0} src={GooglePay} width="36px" />
                        <Image borderRadius={0} src={Visa} width="36px" />
                        <Image borderRadius={0} src={MasterCard} width="36px" />
                        <Image borderRadius={0} src={Amex} width="36px" />
                        <Image borderRadius={0} src={Discover} width="36px" />
                        <Image borderRadius={0} src={JCB} width="36px" />
                        <Image borderRadius={0} src={DinersClub} width="36px" />
                        <Image borderRadius={0} src={UnionPay} width="36px" />
                        <Image borderRadius={0} src={Maestro} width="36px" />
                        <Image borderRadius={0} src={Maestro} width="36px" />
                        <Text size="small" skin="disabled">
                        {t('payment-text')}                              
                        </Text>
                      </Box>
                    </Box>
                  }
                />
                <Card.Content>
                  {open && isUpgraded ? (
                    <EmptyState
                      theme="section"
                      title={t('live-title')}  
                      subtitle={t('live-subtitle')}      
                    >
                      <TextButton
                        prefixIcon={<Icons.ExternalLink />}
                        as={"a"}
                        href={
                          (instanceData as any)["site"]
                            ? (instanceData as any)["site"]["url"]
                            : ""
                        }
                        target={"_blank"}
                      >
                        {t('live-button')}                                 
                      </TextButton>
                    </EmptyState>
                  ) : (
                    <></>
                  )}
                </Card.Content>
              </Card>
            </Cell>
            <Cell span={12}>
              <Card>
                <MarketingLayout
                  size="tiny"
                  title="Waived Stripe fees on your next $20,000 in payment processing"
                  description="Experience enhanced savings with our exclusive Stripe promo code. We offer waived fees on your next $20,000 in payment processing, potentially saving you up to $500. Ideal for growing startups, this offer helps streamline your payment operations without the usual cost. Discover the benefits today and see how you can optimize your payment processing expenses."
                  actions={
                    <Button
                      as="a"
                      href="https://www.joinsecret.com/stripe?via=wing"
                      target="_blank"
                    >
                      Redeem Offer
                    </Button>
                  }
                />
              </Card>
            </Cell>
            <Cell>
              <PageSection title={t('suggested-title')} showDivider />      
            </Cell>
            <Cell span={6}>
              <Card>
                <MarketingLayout
                  title={t('learn-title')}             
                  description={t('learn-description')}
                  actions={
                    <Box gap="SP1">
                      <Button
                        suffixIcon={<Icons.AIFilled />}
                        size="small"
                        skin="standard"
                        onClick={() => {
                          setIsInteractiveDemoOpen(true);
                        }}
                      >
                        {t('demo-play-button')} 
                      </Button>
                      <Button
                        suffixIcon={<Icons.PlayFilled />}
                        size="small"
                        skin="standard"
                        priority="secondary"
                        onClick={() => {
                          setShowVideo(true);
                        }}
                      >
                       {t('watch-play-button')}                         
                      </Button>
                    </Box>
                  }
                  size="tiny"
                  badge={<Badge size="small">{t('tutorial-badge')}</Badge>}         
                />
              </Card>
            </Cell>
            <Cell span={6}>
              <Card>
                <MarketingLayout
                  title={t('contact-title')}                  
                  description={t('contact-description')}
                  actions={
                    <Button
                      size="small"
                      onClick={() => {
                        window.Intercom(
                          "showNewMessage",
                          t('help-msg')
                        );
                      }}
                    >
                      {t('live-chat-button')}
                    </Button>
                  }
                  size="tiny"
                  hiddenBadge
                  alignItems="stretch"
                />
              </Card>
            </Cell>
            <Cell span={12}>
              <BC.Recommends appId="6717f221-8f11-4bc4-9b60-bcd7e95c0268" />
            </Cell>
            <Cell span={12}>
              <BC.Offers appId="6717f221-8f11-4bc4-9b60-bcd7e95c0268" />
            </Cell>
          </Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
